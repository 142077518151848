/**
 * Sets a cookie with value
 *
 * @param name Name of cookie to be set
 * @param value Value of cookie to be set
 * @param days No of days to expire cookie in
 */

module.exports = function setCookie(name, value, days) {
    if (!days) {
        throw new Error('setCookie: missing expiry param');
    }

    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = '; expires=' + date.toGMTString();
    document.cookie = name + '=' + value + expires + '; path=/';
};
