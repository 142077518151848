export const breakpoints = [
    {
        width: 1,
        itemsToShow: 1,
        itemsToScroll: 1,
        itemPadding: [0, 5, 0, 5]
    },
    {
        width: 728,
        itemsToShow: 3,
        itemsToScroll: 3,
        itemPadding: [0, 10, 0, 10]
    },
    {
        width: 1002,
        itemsToShow: 4,
        itemsToScroll: 4,
        itemPadding: [0, 10, 0, 10]
    }
];

export const getPages = (numberOfItems: number) => ({
    sm: Math.ceil(numberOfItems / breakpoints[0].itemsToShow),
    md: Math.ceil(numberOfItems / breakpoints[1].itemsToShow),
    lg: Math.ceil(numberOfItems / breakpoints[2].itemsToShow)
});
