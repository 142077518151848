const { getCpnId } = require('@times-web/utils');
/*
 * https://nidigitalsolutions.jira.com/wiki/display/ACS/Generate+Login+Cookie
 */

const cookieHelper = {};

cookieHelper.deleteCookie = require('./delete-cookie');
cookieHelper.getCips = require('./get-cips');
cookieHelper.getCookieValue = require('./get-cookie-value');
cookieHelper.getCpnId = getCpnId;
cookieHelper.getKeyValuePairs = require('./get-key-value-pairs');
cookieHelper.getVistorId = require('./get-visitor-id');
cookieHelper.setCookie = require('./set-cookie');

module.exports = cookieHelper;
