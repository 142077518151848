/*eslint no-console: ["error", { allow: ["info"] }] */
module.exports = [
    {
        context: 'small',
        match: function() {
            nuk.page.breakpoint = 'small';
            console.info('MQ:small');
        },
        unmatch: function() {
            console.info('leaving MQ:small');
        }
    },
    {
        context: 'medium',
        match: function() {
            nuk.page.breakpoint = 'medium';
            console.info('MQ:medium');
        },
        unmatch: function() {
            console.info('leaving MQ:medium');
        }
    },
    {
        context: 'wide',
        match: function() {
            nuk.page.breakpoint = 'wide';
            console.info('MQ:wide');
        },
        unmatch: function() {
            console.info('leaving MQ:wide');
        }
    },
    {
        context: 'huge',
        match: function() {
            nuk.page.breakpoint = 'huge';
            console.info('MQ:huge');
        },
        unmatch: function() {
            console.info('leaving MQ:huge');
        }
    }
];
