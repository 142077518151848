import axios from 'axios';
import memoizee from 'memoizee';
import { URL as Url } from 'url';

const timeout = 2000;

function extractNames(values: Record<string, string>[]) {
    const cleansedValues = new Set(
        values.map((value: Record<string, string>) =>
            value.name
                .toLowerCase()
                .trim()
                .replace(/\s+/g, '_')
                .replace(/["'=!+#*~;^()<>[\],&]/g, '')
        )
    );

    return [...cleansedValues].join(',');
}

export default memoizee(function() {
    const url =
        'https://euasync01.admantx.com/admantx/service?request=' +
        encodeURIComponent(
            JSON.stringify({
                key:
                    'f1694ae18c17dc1475ee187e4996ad2b484217b1a436cb04b7ac3dd4902180b6',
                method: 'descriptor',
                mode: 'async',
                decorator: 'json',
                filter: 'default',
                type: 'URL',
                body: Url
            })
        );
    return axios
        .get(url, {
            timeout: timeout
        })
        .then(function(response) {
            const data = response.data;
            const hasUseableData =
                data.admants ||
                data.categories ||
                data.entities ||
                data.feelings;

            if (response.statusText !== 'OK' || !hasUseableData) {
                return Promise.reject(response);
            }

            return {
                admantx_bs: extractNames(data.admants),
                admantx_cat: extractNames(data.categories),
                admantx_emotion: extractNames(data.feelings),
                admantx_ents: extractNames(data.entities)
            };
        })
        .catch(function() {
            return {
                admantx_bs: '',
                admantx_cat: '',
                admantx_emotion: '',
                admantx_ents: ''
            };
        });
});
