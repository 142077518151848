const CPN_ID_KEY = 'eid';

const decodeCookie = (value: string) => {
    if (!value) {
        return;
    }

    try {
        return decodeURIComponent(value);
    } catch (e) {
        return;
    }
};

export const parseCookie = (
    value: string,
    valueSeparator: string,
    paramSeparator: string
) => {
    value = decodeCookie(value) || '';

    return value.split(valueSeparator).reduce((res: Record<string, string>, param: string) => {
        const parts: Array<string> = param.split(paramSeparator);
        res[parts[0]] = parts[1];
        return res;
    }, {});
};

export const getCpnId = (value: string) => {
    const cookie: Record<string,string> = parseCookie(value, '&', '=');
    return cookie[CPN_ID_KEY] || null;
};
