const { parseCookie } = require('@times-web/utils');

const CIPS_KEY = 'cips';

/**
 * Extracts Cips value from ACS login cookie
 *
 * @return {String|null}
 */
module.exports = function getCips(value) {
    const cookie = parseCookie(value, '&', '=');
    return cookie[CIPS_KEY] || null;
};
