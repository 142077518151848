import { DefaultTheme } from 'styled-components';

import { paywallColors, colors } from './colors';

const DarkComponentTheme: DefaultTheme = {
    backgroundColors: {
        main: paywallColors.secondaryBackgroundColor
    },
    fontColors: {
        main: colors.mainWhite,
        secondary: colors.mainWhite
    },
    borderColor: '#5F646A',
    borderTop: '0px'
};

export { DarkComponentTheme };
