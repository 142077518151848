const { parseCookie } = require('@times-web/utils');

const VISITOR_ID_KEY = 'v_id';

/**
 * Extracts getVistorId value from utag_main cookie
 *
 * @return {String|null}
 */
module.exports = function getVistorId(value) {
    const cookie = parseCookie(value, '$', ':');
    return cookie[VISITOR_ID_KEY] || null;
};
