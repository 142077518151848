const util = require('util');
const assignIn = require('lodash/assignIn');
const bind = require('lodash/bind');
const EventEmitter = require('events').EventEmitter;
const MQ = require('../ui/base');

const DEFAULTS = {
    isActive: false,
    activeClass: 'is-active',
    hasModal: false,
    modalClass: 'Modal',
    modalActiveClass: 'is-modalOpen',
    modalClassModifier: 'transparent',
    modalParent: 'body'
};

const Dropdown = function($trigger, $target, options) {
    if (!$trigger || !$target) {
        throw new Error(
            'Argument exception: $trigger and $target cannot be null or undefined.'
        );
    }

    this.$trigger = $trigger;
    this.$target = $target;
    this.options = assignIn({}, DEFAULTS, options);
    this.$modalParent = $(this.options.modalParent);

    this.isActive = this.options.isActive;

    if (this.options.hasModal) {
        this.createModal();
    }

    this.bindEvents();
};

// Extend EventEmitter
Dropdown.prototype = Object.create(EventEmitter.prototype);

Dropdown.prototype.createModal = function() {
    var modalClass = util.format(
        '%s %s--%s',
        this.options.modalClass,
        this.options.modalClass,
        this.options.modalClassModifier
    );
    this.$modal = $('<div />', { class: modalClass });
    this.$modalParent.append(this.$modal);
};

Dropdown.prototype.bindEvents = function() {
    this.$trigger.on('click', bind(this.onTriggerClicked, this));

    if (this.options.hasModal) {
        this.$modal.on('click', bind(this.close, this));
    }

    if (!this.isActive) {
        MQ.addQuery({
            context: ['small', 'medium'],
            match: bind(this.close, this)
        });
    }
};

Dropdown.prototype.onTriggerClicked = function(event) {
    event.preventDefault();
    this.isActive ? this.close() : this.open();
};

Dropdown.prototype.onTargetClicked = function(event) {
    event.preventDefault();

    const selected = $(event.target).text();
    this.$trigger.find('.Dropdown-placeholder').text(selected);
    this.close();
};

Dropdown.prototype.open = function() {
    if (this.isActive) {
        return;
    }

    this.$trigger.addClass(this.options.activeClass);
    this.$target.addClass(this.options.activeClass);

    if (this.options.hasModal) {
        this.$modal.addClass(this.options.modalActiveClass);
    }

    this.isActive = true;
    this.updateAriaAttribute('expanded', true);
    this.emit('open');
};

Dropdown.prototype.close = function() {
    if (!this.isActive) {
        return;
    }

    this.$trigger.removeClass(this.options.activeClass);
    this.$target.removeClass(this.options.activeClass);

    if (this.options.hasModal) {
        this.$modal.removeClass(this.options.modalActiveClass);
    }

    this.isActive = false;
    this.updateAriaAttribute('expanded', false);
    this.emit('close');
};

Dropdown.prototype.updateAriaAttribute = function(name, value) {
    this.$trigger.attr(util.format('aria-%s', name), value);
    this.$target.attr(util.format('aria-%s', name), value);
};

module.exports = Dropdown;
