const { parseCookie } = require('@times-web/utils');

/**
 * Return object containing key value pairs
 *
 * @return {Object}
 */
module.exports = function getKeyValuePairs(value) {
    return parseCookie(value, ':');
};
