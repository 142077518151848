const msPerFrame = 16;

// Simplified from https://gist.github.com/paulirish/1579671
function createRequestFrame() {
    let lastTime = 0;
    return function requestFrame(callback) {
        const now = Date.now();
        const delay = Math.max(0, msPerFrame - (now - lastTime));
        window.setTimeout(callback, delay);
        lastTime = now + delay;
    };
}

module.exports = createRequestFrame;
