const requestFrame =
    window.requestAnimationFrame || require('./request-animation-frame')();
const throttle = require('lodash/throttle');

const subscriberCallbacks = [];
let ticking = false;

module.exports.subscribe = function(wait, callback) {
    subscriberCallbacks.push(throttle(callback, wait));
    ticking = true;
    requestFrame(notifySubscribers);
};

module.exports.clear = function() {
    subscriberCallbacks.splice(0, subscriberCallbacks.length);
    ticking = false;
};

function notifySubscribers() {
    subscriberCallbacks.forEach(callback => {
        callback();
    });

    if (ticking) {
        requestFrame(notifySubscribers);
    }
}
