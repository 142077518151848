import { DefaultTheme } from 'styled-components';

import { paywallColors, colors } from './colors';

const LightComponentTheme: DefaultTheme = {
    backgroundColors: {
        main: paywallColors.primaryBackgroundColor
    },
    fontColors: {
        main: colors.mainBlack,
        secondary: paywallColors.altMidGrey
    },
    borderColor: '#D8D8D8',
    borderTop: '1px solid #DBDBDB'
};

export { LightComponentTheme };
