function getGlobalScript(name: string, src: string): Promise<any> {
    return new Promise((resolve, reject) => {
        if (window[name as any]) {
            return resolve(window[name as any]);
        }

        const script = document.querySelector(`script[src="${src}"]`);

        if (!script) {
            return reject(new Error('Error getting script element'));
        }

        script.addEventListener('load', () => resolve(window[name as any]));

        script.addEventListener('error', () =>
            reject(new Error('Error loading script'))
        );
    });
}

export { getGlobalScript };
