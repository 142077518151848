const DataAttributeHelper = {
    getJSON: function(attr, element) {
        const value = $(element).data(attr);
        return value ? value : {};
    },
    storeJSON: function(attr, element, data) {
        $(element).attr('data-' + attr, JSON.stringify(data));
    }
};

module.exports = DataAttributeHelper;
